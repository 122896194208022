import React from 'react'
import { Helmet } from 'react-helmet'
import { pageLoad } from '../js/functions'
import { InView } from 'react-intersection-observer'

import Header from '../components/Header'
import Footer from '../components/Footer'
// import Breadcrumb from '../components/Breadcrumb';
import CaseList from '../components/CaseList'
import ServiceList from '../components/ServiceList'
import BgLink from '../components/BgLink'

class HomePage extends React.Component {
  componentDidMount() {
    pageLoad()
  }

  render() {
    const header = {
      // sub: "Who We Are",
      main: 'Business Advisory for <br />international clients in China',
      sub2: 'Insight · Connect · Acumen',
      // sub3:
      // '<span>HR & Solutions</span><span>Mergers & Acquisitions</span><span>Financial & Taxation</span>',
      image: '/images/banner/main.jpg',
      className: 'home',
    }

    return (
      <div>
        <Helmet>
          <title>
            HGR Consulting | Business Advisory for International Clients in
            China
          </title>
          <meta
            name='description'
            content='Business consultancy, financial advisory & accounting firm in Shanghai. Serving international clients doing or planning a business in China.'
          />
        </Helmet>

        <Header params={header} />

        <div className='main' id='home'>
          {/* ABOUT - INTRO */}
          <InView>
            {({ inView, ref }) => (
              <>
                <div
                  className={
                    'block pad-sides text-image intro ' +
                    (inView ? 'in-view' : '')
                  }
                  ref={ref}
                >
                  <div className='inner narrower fade-in'>
                    <div className='text'>
                      <h2>Our Story</h2>
                      <p>
                        Having the right product or service is just half of the
                        battle when measuring success in one of the most dynamic
                        economies in the world. Focusing on revenue growth does
                        not leave time to manage the small details that could
                        leave profits on the table. Finding a balance to manage
                        the complex regulatory and compliance framework requires
                        partnering with a firm that understands your particular
                        business needs.
                      </p>
                      <p>
                        That is where our story begins. Founded in 2012, HGR
                        Consulting sought to fill the gap in service that many
                        startups and companies expanding in China needed;
                        advisory services that provide quality information and
                        relevant to their specific business needs.
                      </p>
                      <p>
                        HGR Consulting offers global firm expertise without
                        losing sight of the unique challenges each company
                        faces. With the idea that you can't manage what you
                        can't see, we partner with our clients to elevate their
                        place in China.
                      </p>
                      {/* <p className="more"><Link to="">Read More »</Link></p> */}
                    </div>
                    <div className='image'>
                      <img src='/images/content/painting-he.jpg' alt='' />
                    </div>
                  </div>

                  <div className='button-holder center-holder fade-in'>
                    <BgLink
                      to='/about'
                      text='Read More'
                      isButton={true}
                      isFilledGreen={true}
                    />
                  </div>
                </div>
              </>
            )}
          </InView>

          {/* CASE STUDIES */}
          <InView>
            {({ inView, ref }) => (
              <div className={inView ? 'in-view' : ''} ref={ref}>
                <div className='block pad-sides divider center'>
                  <div className='inner fade-in'>
                    <div className='line'></div>
                  </div>
                </div>

                <div className='block pad-sides cases'>
                  <div className='inner'>
                    <div className='text fade-in'>
                      <h4>Case Studies</h4>
                      <h1>Real Advice for Real Companies</h1>
                    </div>
                    <CaseList limit={6} />
                    <div className='button-holder center-holder fade-in'>
                      <BgLink
                        to='/case-studies'
                        text='Read More'
                        isButton={true}
                        isFilledGreen={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </InView>

          {/* FULL IMAGE */}
          {/* <InView>
            {({ inView, ref }) => (
              <div
                className={
                  'block image-full fade-in ' + (inView ? 'in-view' : '')
                }
                ref={ref}
              >
                <img
                  src='/images/content/istockphoto-1083443534-1024x1024.jpg'
                  alt=''
                />
              </div>
            )}
          </InView> */}

          {/* SERVICES  */}
          <div className='block pad-sides services'>
            <div className='inner'>
              <InView>
                {({ inView, ref }) => (
                  <div
                    className={'text fade-in ' + (inView ? 'in-view' : '')}
                    ref={ref}
                  >
                    <h4>Services We Provide</h4>
                    <h1>
                      Our Suite of Services Manage the Details so you stay
                      focused on the big picture.
                    </h1>
                  </div>
                )}
              </InView>
              <ServiceList limit={4} />
              <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'button-holder center-holder fade-in ' +
                      (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <BgLink
                      to='/services'
                      text='Read More'
                      isButton={true}
                      isFilledGreen={true}
                    />
                  </div>
                )}
              </InView>
            </div>
          </div>

          {/* <InView>
            {({ inView, ref }) => (
              <div className={inView ? 'in-view' : ''} ref={ref}>
                <div className='block pad-sides divider after-services'>
                  <div className='inner fade-in'>
                    <div className='line'></div>
                  </div>
                </div>

                <div className='block pad-sides pad-bottom text-image'>
                  <div className='inner fade-in'>
                    <div className='image'>
                      <img
                        src='/images/content/office-meeting-room.jpg'
                        alt=''
                      />
                    </div>
                    <div className='text'>
                      <h4>About HGR Consulting</h4>
                      <h1>Global Experience · Personal Touch</h1>
                      <BgLink to='/about' text='About Us »' isButton={true} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </InView> */}

          <Footer hasUpper={true} />
        </div>
      </div>
    )
  }
}

export default HomePage
