import React from 'react'
import { Link } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

const ServiceItem = ({ post }) => {
  const { id, title, slug, featuredImage } = post
  const { excerpt } = post.acf
  const [ref, inView] = useInView()

  return (
    <div
      key={id}
      className={`item fade-in ${inView ? 'in-view' : ''}`}
      ref={ref}
    >
      <Link to={'/services/' + slug}>
        <img src={featuredImage.sourceUrl} alt='' />
        <div className='text'>
          <h3 dangerouslySetInnerHTML={{ __html: title }} />
          <p dangerouslySetInnerHTML={{ __html: excerpt }} />
        </div>
      </Link>
    </div>
  )
}

export default ServiceItem
