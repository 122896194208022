import React from 'react'
// import { Trans } from "@lingui/macro";
// import { pageLoad } from '../js/functions';
import Header from '../components/Header'

class NotFoundPage extends React.Component {
  componentDidMount() {
    // pageLoad();
  }

  render() {
    const header = {
      sub: '404: not found',
      image: '/images/banner/main.jpg',
      className: 'not-found',
    }

    return (
      <div>
        <Header params={header} />
      </div>
    )
  }
}

export default NotFoundPage
