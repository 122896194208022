import React from 'react'
import { Helmet } from 'react-helmet'
import { pageLoad } from '../js/functions'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Breadcrumb from '../components/Breadcrumb'
import ScrollDown from '../components/ScrollDown'
import { InView } from 'react-intersection-observer'

// import CaseList from '../components/CaseList'
// import ServiceList from '../components/ServiceList'
// import BgLink from '../components/BgLink'
import NotFoundPage from '../pages/NotFoundPage'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

const GET_SERVICE = gql`
  query GET_SERVICE($slug: String!) {
    serviceBy(slug: $slug) {
      title
      date
      slug
      featuredImage {
        sourceUrl
      }
      acf {
        titleBlock
        block1Text
        block2Text
        block3Text
        photoMid {
          sourceUrl
        }
      }
    }
  }
`

class ServicesItemPage extends React.Component {
  componentDidMount() {
    pageLoad()
  }

  render() {
    let baseSlug = this.props.match.params.slug // from router?
    // if (thisEnv.lan === 'zh')  baseSlug += '_zh';

    const variables = { slug: baseSlug }

    return (
      <Query query={GET_SERVICE} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) return <div className='loader' />
          if (error) return <NotFoundPage />

          const service = data.serviceBy
          return <ServicesItemPageInner props={service} />
        }}
      </Query>
    )
  }
}

class ServicesItemPageInner extends React.Component {
  render() {
    const service = this.props.props
    const serviceTitleString = service.title.replace('#038;', '')

    const header = {
      sub: 'Our Services',
      main: service.title,
      image: '/images/banner/main.jpg',
    }
    const breadcrumb = [
      {
        text: 'Home',
        link: '/',
      },
      {
        text: 'Our Services',
        link: '/services',
      },
      {
        text: service.title,
      },
    ]

    return (
      <div>
        <Helmet>
          <title>{'HGR Consulting | Services | ' + serviceTitleString}</title>
        </Helmet>

        <Header params={header} />
        <Breadcrumb items={breadcrumb} />

        <InView>
          {({ inView, ref }) => (
            <div
              id='service-item-page'
              className={`main fade-in ${
                inView ? 'in-view-wrap' : ''
              } service-${service.slug}`}
              ref={ref}
            >
              <ScrollDown />

              <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block pad-sides title ' + (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <h1
                        dangerouslySetInnerHTML={{
                          __html: service.acf.titleBlock,
                        }}
                      />
                    </div>
                  </div>
                )}
              </InView>

              {/* BLOCK 1 - INTRO */}
              <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block block-1 pad-sides text-image ' +
                      (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <div
                        className='text'
                        dangerouslySetInnerHTML={{
                          __html: service.acf.block1Text,
                        }}
                      />
                      <div className='image'>
                        {service.featuredImage &&
                        service.featuredImage.sourceUrl && ( // If a featured image exists, display it.
                            <img src={service.featuredImage.sourceUrl} alt='' />
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </InView>

              {/* {service.slug === 'business-financial-advisory' && (
                <InView>
                  {({ inView, ref }) => (
                    <div
                      className={
                        'block block-split pad-sides text-image ' +
                        (inView ? 'in-view' : '')
                      }
                      ref={ref}
                    >
                      <div className='inner fade-in'>
                        <div className='text'>
                          <h4>Corporate Strategy and Financial Planning</h4>
                          <p>
                            Our team in Shanghai provides tailored services for
                            each client on their market entry and growth
                            strategy, business performance and profitability,
                            corporate governance, acquisitions and divestitures,
                            business and banking relationships, and overall
                            financial, tax and risk management planning.
                          </p>
                        </div>
                        <div className='text-2'>
                          <h4>
                            Financial Operations Design, Deployment &amp;
                            Management
                          </h4>
                          <p>
                            We have helped clients on their executive management
                            reporting, accounting system design, internal
                            controls, cash-flow assumptions, budgeting, capital
                            requests, fundraising and debt management.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </InView>
              )} */}

              {/* BLOCK 2 - MORE */}
              {service.acf.block2Text && (
                <InView>
                  {({ inView, ref }) => (
                    <div
                      className={
                        'block block-2 pad-sides text-image image-bigger bg-light-grey ' +
                        (inView ? 'in-view' : '')
                      }
                      ref={ref}
                    >
                      <div className='inner fade-in'>
                        <div className='image'>
                          <img src={service.acf.photoMid.sourceUrl} alt='' />
                        </div>
                        <div
                          className='text'
                          dangerouslySetInnerHTML={{
                            __html: service.acf.block2Text,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </InView>
              )}

              {/* BLOCK 3 - SERVICE LIST */}
              <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block block-3 pad-sides text-image image-bigger ' +
                      (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='bg' />
                    <div className='inner fade-in'>
                      <div
                        className='text'
                        dangerouslySetInnerHTML={{
                          __html: service.acf.block3Text,
                        }}
                      />
                    </div>
                  </div>
                )}
              </InView>

              {/* CASE STUDIES */}
              {/* <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block pad-sides cases bg-light-grey ' +
                      (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <div className='text center-holder'>
                        <h4>Related Case Studies</h4>
                      </div>
                      <CaseList limit={3} />
                      <div
                        className='center-holder'
                        style={{ marginTop: '30px' }}
                      >
                        <BgLink
                          to='/case-studies'
                          text='All Case Studies »'
                          isButton={true}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </InView> */}

              {/* MORE SERVICES - INTRO */}
              {/* <div className="block pad-sides divider center">
                <div className="inner">
                  <div className="line"></div>
                </div>
              </div> */}

              {/* <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block pad-sides pad-bottom ' + (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <div className='center-holder'>
                        <h4>More Services</h4>
                      </div>
                      <ServiceList limit={2} currentSlug={service.slug} />
                      <div
                        className='center-holder'
                        style={{ marginTop: '30px' }}
                      >
                        <BgLink
                          to='/services'
                          text='All Services »'
                          isButton={true}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </InView> */}
            </div>
          )}
        </InView>
        <Footer hasUpper={true} />
      </div>
    )
  }
}

export default ServicesItemPage
