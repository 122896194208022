import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
// import { TransitionGroup, CSSTransition } from "react-transition-group";

import HomePage from '../pages/HomePage'
import AboutPage from '../pages/AboutPage'
import ServicesPage from '../pages/ServicesPage'
import ServicesItemPage from '../pages/ServicesItemPage'
import CaseStudiesPage from '../pages/CaseStudiesPage'
import CaseStudiesItemPage from '../pages/CaseStudiesItemPage'
import ContactPage from '../pages/ContactPage'
import NotFoundPage from '../pages/NotFoundPage'

function Container({ location }) {
  return (
    <div>
      {/* <TransitionGroup>
      <CSSTransition
        key={location.key}
        timeout={{ enter:300, exit: 300 }}
        classNames={'fade'}
      >

        <div className="transition-wrap"> */}
      <Switch location={location}>
        <Route path='/' component={HomePage} exact={true} />
        <Route path='/about' component={AboutPage} exact={true} />
        <Route path='/services' component={ServicesPage} exact={true} />
        <Route
          path='/services/:slug'
          component={ServicesItemPage}
          exact={true}
        />
        <Route path='/case-studies' component={CaseStudiesPage} exact={true} />
        <Route
          path='/case-studies/:slug'
          component={CaseStudiesItemPage}
          exact={true}
        />
        <Route path='/contact' component={ContactPage} exact={true} />
        <Route component={NotFoundPage} />
      </Switch>
      {/* </div>

      </CSSTransition>
      </TransitionGroup> */}
    </div>
  )
}

export default withRouter(Container)
