import React from 'react'
import { Link } from 'react-router-dom'

const BgLink = (props) => {
  const { to, text, external, isButton, isFilledGreen, isWhite } = props
  if (external) {
    return (
      <a
        href={to}
        target='_blank'
        rel='noopener noreferrer'
        className={
          'bg-link styled' +
          (isButton ? ' button' : '') +
          (isFilledGreen ? ' filled-green' : '') +
          (isWhite ? ' white' : '')
        }
      >
        <span className='text'>{text}</span>
        <span className='bg'>
          <span className='inner-text'>{text}</span>
        </span>
      </a>
    )
  } else {
    return (
      <Link
        to={to}
        className={
          'bg-link styled' +
          (isButton ? ' button' : '') +
          (isFilledGreen ? ' filled-green' : '') +
          (isWhite ? ' white' : '')
        }
      >
        <span className='text'>{text}</span>
        <span className='bg'>
          <span className='inner-text'>{text}</span>
        </span>
      </Link>
    )
  }
}

export default BgLink
