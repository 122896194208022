// const testing = false;

export const pageLoad = () => {
  // if (testing) {
  //   document.querySelector('#main-wrap > div').classList.add('page-loaded');
  // } else {
  // setTimeout( () => {
  window.scrollTo(0, 0)
  // }, 300);
  //   setTimeout( () => {
  //     document.querySelector('#main-wrap > div').classList.add('page-loaded');
  //   }, 600);
  // }
}
