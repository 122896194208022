import React from 'react'
// import styles from '../styles/components/Header.scss';
import LineLink from '../components/LineLink'
import { useInView } from 'react-intersection-observer'
import ScrollDown from '../components/ScrollDown'

const Header = (props) => {
  const { sub, main, sub2, sub3, className } = props.params
  const [ref, inView] = useInView()
  return (
    <div
      id='header'
      className={className + (inView ? ' in-view' : '')}
      ref={ref}
    >
      <div className='banner-bg-wrap abs-fill fade-in'>
        <div
          className='banner-bg abs-fill img-bg-cover'
          style={{ backgroundImage: 'url(' + props.params.image + ')' }}
        ></div>
      </div>
      <div className='inner fade-in'>
        {className === 'home' && (
          <div className='chars-h'>
            <img src='images/vi/dao-jun-h.png' alt='道均' />
          </div>
        )}
        <div className='table-wrap'>
          {className === 'home' && (
            <div className='chars'>
              <img src='images/vi/dao-jun.png' alt='道均' />
            </div>
          )}
          <div className='text'>
            {sub && <h4 dangerouslySetInnerHTML={{ __html: sub }} />}
            {main && <h1 dangerouslySetInnerHTML={{ __html: main }} />}
            {className === 'not-found' && (
              <h1>
                <LineLink to='/' text='Return to Home' />
              </h1>
            )}
            {sub2 && (
              <h4 className='sub2' dangerouslySetInnerHTML={{ __html: sub2 }} />
            )}
            {sub3 && (
              <h4 className='sub3' dangerouslySetInnerHTML={{ __html: sub3 }} />
            )}
          </div>
        </div>
      </div>

      {className === 'home' && <ScrollDown />}
    </div>
  )
}
export default Header
