import React from 'react'
import { Helmet } from 'react-helmet'
import { pageLoad } from '../js/functions'
import { InView } from 'react-intersection-observer'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Breadcrumb from '../components/Breadcrumb'
import CaseList from '../components/CaseList'
import ScrollDown from '../components/ScrollDown'

class CaseStudiesPage extends React.Component {
  componentDidMount() {
    pageLoad()
  }

  render() {
    const header = {
      sub: 'Case Studies · Our Milestone',
      main:
        'Providing Real Companies with Real Solutions Elevating Your Business in China',
      image: '/images/banner/painting-red-army.jpg',
      className: 'cases-list',
    }
    const breadcrumb = [
      {
        text: 'Home',
        link: '/',
      },
      {
        text: 'Case Studies',
      },
    ]

    return (
      <div>
        <Helmet>
          <title>
            HGR Consulting | Case Studies: How We Helped Our Clients
          </title>
        </Helmet>

        <Header params={header} />
        <Breadcrumb items={breadcrumb} />

        <InView>
          {({ inView, ref }) => (
            <div
              className={'main fade-in ' + (inView ? 'in-view-wrap' : '')}
              ref={ref}
            >
              <ScrollDown />

              <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block pad-sides title ' + (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <h1>
                        The HGR Consulting team provides quality, value-added
                        advice, and services to each of our clients so they can
                        focus on success. We ensure our clients have the
                        regulatory backing and business acumen to meet
                        objectives, minimize risks, and optimize their China
                        businesses.
                      </h1>
                    </div>
                  </div>
                )}
              </InView>

              <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block pad-sides divider center ' +
                      (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <div className='line'></div>
                    </div>
                  </div>
                )}
              </InView>

              <div className='block pad-sides pad-bottom'>
                <div className='inner'>
                  <InView>
                    {({ inView, ref }) => (
                      <div
                        className={
                          'center-holder fade-in ' + (inView ? 'in-view' : '')
                        }
                        ref={ref}
                      >
                        <h4>Case Studies List</h4>
                      </div>
                    )}
                  </InView>
                  <CaseList hasExcerpt={true} />
                </div>
              </div>
            </div>
          )}
        </InView>

        <Footer hasUpper={true} />
      </div>
    )
  }
}

export default CaseStudiesPage
