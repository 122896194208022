import React from 'react'
import { Helmet } from 'react-helmet'
import { pageLoad } from '../js/functions'
import { InView } from 'react-intersection-observer'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Breadcrumb from '../components/Breadcrumb'
import ScrollDown from '../components/ScrollDown'

class ContactPage extends React.Component {
  componentDidMount() {
    pageLoad()
  }

  render() {
    const header = {
      sub: 'Contact Us',
      main: 'Bespoke Solutions for <br />Growth in China.',
      image: '/images/banner/main.jpg',
      className: 'contact',
    }
    const breadcrumb = [
      {
        text: 'Home',
        link: '/',
      },
      {
        text: 'Contact Us',
      },
    ]

    const contactItems = {
      phone: {
        title: 'Phone',
        description: (
          <a className='line-link' href='tel:+862133602331'>
            <div className='inner'>+86 21 3360 2331</div>
          </a>
        ),
        icon: 'phone.png',
        isLineLink: true,
      },
      email: {
        title: 'Email',
        description: (
          <a
            href='mailto:info@hgr-consulting.com?subject=An inquiry from the HGR Consulting website'
            className='line-link'
          >
            <div className='inner'>info@hgr-consulting.com</div>
          </a>
        ),
        icon: 'email.png',
        isLineLink: true,
      },
      linkedIn: {
        icon: 'linkedin.png',
        iconLink: 'https://www.linkedin.com/company/hgr-consulting-ltd',
        title: '&nbsp;',
      },
      // facebook: {
      //   icon: 'facebook.png',
      //   iconLink: 'https://www.facebook.com',
      //   title: '&nbsp;',
      // },
      address: {
        title: 'Address',
        description: `600 Shaanxi Bei Road<br />
          Block 11, RM 207<br />
          Jing’An District<br />
          Shanghai PRC 200040`,
        icon: 'map.png',
      },
      officeHours: {
        title: 'Office Hours',
        description: 'Monday – Friday<br />9:00am – 6:00pm',
        icon: 'clock.png',
      },
    }

    return (
      <div>
        <Helmet>
          <title>
            HGR Consulting | Contact Us: Bespoke Solutions for Growth in China
          </title>
        </Helmet>

        <Header params={header} />
        <Breadcrumb items={breadcrumb} />

        <InView>
          {({ inView, ref }) => (
            <div
              id='contact'
              className={'main fade-in ' + (inView ? 'in-view-wrap' : '')}
              ref={ref}
            >
              <ScrollDown />

              {/* TITLE */}
              <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block title pad-sides ' + (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <h1>
                        If you would like to explore how we can help you with
                        your China business, feel free to contact us.
                      </h1>
                    </div>
                  </div>
                )}
              </InView>

              {/* PHONE/EMAIL */}
              <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block phone-email pad-sides bg-light-grey ' +
                      (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <ContactItem data={contactItems.phone} />
                      <ContactItem data={contactItems.email} />
                      <ContactItem data={contactItems.linkedIn} />
                      {/* <ContactItem data={contactItems.facebook} /> */}
                    </div>
                  </div>
                )}
              </InView>

              {/* IMAGE / ADDRESS/OFFICE HOURS */}
              <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block image-address pad-sides ' +
                      (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <div className='image'>
                        <img src='/images/content/wall-logo.jpg' alt='' />
                      </div>
                      <div className='text'>
                        <ContactItem data={contactItems.address} />
                        <ContactItem data={contactItems.officeHours} />
                      </div>
                    </div>
                  </div>
                )}
              </InView>
            </div>
          )}
        </InView>

        <Footer isFull={true} />
      </div>
    )
  }
}

const ContactItem = ({ data }) => {
  const { title, description, icon, iconLink, isLineLink } = data
  return (
    <div className='contact-item' key={title}>
      <div className='inner'>
        {iconLink && (
          <a
            className='icon icon-holder'
            href={iconLink}
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={'/images/icons/' + icon} alt='' />
          </a>
        )}
        {!iconLink && (
          <img className='icon' src={'/images/icons/' + icon} alt='' />
        )}
        <h4 dangerouslySetInnerHTML={{ __html: title }} />
        {isLineLink && <h5>{description}</h5>}
        {!isLineLink && <p dangerouslySetInnerHTML={{ __html: description }} />}
      </div>
    </div>
  )
}

export default ContactPage
