import React from 'react'
import LineLink from '../components/LineLink'
import { useInView } from 'react-intersection-observer'

const Breadcrumb = (props) => {
  const { items } = props
  const [ref, inView] = useInView()

  return (
    <div
      className={'breadcrumb pad-sides fade-in ' + (inView ? 'in-view' : '')}
      ref={ref}
    >
      <div className='inner'>
        <p>
          {items.map((item, index) => {
            return <Item key={index} index={index} params={item} />
          })}
        </p>
      </div>
    </div>
  )
}

const Item = (props) => {
  const { text, link } = props.params
  const { index } = props
  if (index === 0) {
    return (
      <span>
        <LineLink to={link} text={text} />
      </span>
    )
  } else if (link) {
    return (
      <span>
        <span className='arrow-right'></span>
        <LineLink to={link} text={text} />
      </span>
    )
  } else {
    // is active
    return (
      <span>
        <span className='arrow-right'></span>
        <span className='active' dangerouslySetInnerHTML={{ __html: text }} />
      </span>
    )
  }
}

export default Breadcrumb
