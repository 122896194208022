import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
    this.menuToggle = this.menuToggle.bind(this)
    this.menuClose = this.menuClose.bind(this)
  }

  menuToggle() {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
    }))
  }
  menuClose() {
    this.setState({
      menuOpen: false,
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.menuClose()
    }
  }

  render() {
    const { firstLoadLoaded } = this.props
    return (
      <div id='menu' className={this.state.menuOpen ? 'open' : ''}>
        <div
          className={'thumb ' + (firstLoadLoaded ? 'in' : '')}
          onClick={this.menuToggle}
        >
          <div className='one'></div>
          <div className='two'></div>
          <div className='three'></div>
        </div>
        <div className='outer'>
          <div className={'loaded-wrap ' + (firstLoadLoaded ? '' : 'up')}>
            <div className='inner'>
              <NavLink className='logo' to='/'>
                <img src='/images/vi/hgr_logo_white.png' alt='logo' />
              </NavLink>
              {/* <div className="thumb" onClick={this.props.headerToggle}> */}

              <ul>
                <li>
                  <NavLink to='/' exact={true}>
                    <div className='inner'>Home</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/case-studies'>
                    <div className='inner'>Case Studies</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/services'>
                    <div className='inner'>Services</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/about'>
                    <div className='inner'>About</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/contact'>
                    <div className='inner'>Contact</div>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Menu)
