import React from 'react'
import { Helmet } from 'react-helmet'
import { pageLoad } from '../js/functions'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Breadcrumb from '../components/Breadcrumb'
import ScrollDown from '../components/ScrollDown'
import { InView } from 'react-intersection-observer'

// import CaseList from '../components/CaseList'
// import ServiceList from '../components/ServiceList'
// import BgLink from '../components/BgLink'
import NotFoundPage from '../pages/NotFoundPage'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

const GET_CASE = gql`
  query GET_CASE($slug: String!) {
    case_studyBy(slug: $slug) {
      title
      date
      slug
      featuredImage {
        sourceUrl
      }
      acf {
        titleBlock
        bodyClient
        bodyTask
        bodyAction
      }
    }
  }
`

class CaseStudiesItemPage extends React.Component {
  componentDidMount() {
    pageLoad()
  }

  render() {
    let baseSlug = this.props.match.params.slug // from router?
    // if (thisEnv.lan === 'zh')  baseSlug += '_zh';

    const variables = { slug: baseSlug }

    return (
      <Query query={GET_CASE} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) return <div className='loader' />
          if (error) return <NotFoundPage />

          const caseStudy = data.case_studyBy
          return <CaseStudiesItemPageInner props={caseStudy} />
        }}
      </Query>
    )
  }
}

class CaseStudiesItemPageInner extends React.Component {
  render() {
    const caseStudy = this.props.props

    const header = {
      sub: 'Case Studies',
      main: caseStudy.title,
      image: '/images/banner/painting-red-army.jpg',
      className: 'cases-list',
    }
    const breadcrumb = [
      {
        text: 'Home',
        link: '/',
      },
      {
        text: 'Case Studies',
        link: '/case-studies',
      },
      {
        text: caseStudy.title,
      },
    ]

    return (
      <div>
        <Helmet>
          <title>{'HGR Consulting | Case Studies | ' + caseStudy.title}</title>
        </Helmet>
        <Header params={header} />
        <Breadcrumb items={breadcrumb} />
        <InView>
          {({ inView, ref }) => (
            <div
              id='case-item-page'
              className={'main fade-in ' + (inView ? 'in-view-wrap' : '')}
              ref={ref}
            >
              <ScrollDown />

              {/* BLOCK 1 - INTRO */}
              <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block block-1 pad-sides ' + (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <div className='text left'>
                        <h2
                          dangerouslySetInnerHTML={{
                            __html: caseStudy.acf.titleBlock,
                          }}
                        />
                      </div>
                      <div className='image right'>
                        {caseStudy.featuredImage &&
                        caseStudy.featuredImage.sourceUrl && ( // If a featured image exists, display it.
                            <img
                              src={caseStudy.featuredImage.sourceUrl}
                              alt=''
                            />
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </InView>

              {/* BLOCK 2 - DETAILS */}
              <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block block-2 pad-sides bg-light-grey ' +
                      (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <div className='text left'>
                        <div className='client'>
                          <h3>Client:</h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: caseStudy.acf.bodyClient,
                            }}
                          />
                        </div>
                        <div className='task'>
                          <h3>Task/Facts:</h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: caseStudy.acf.bodyTask,
                            }}
                          />
                        </div>
                      </div>
                      <div className='text-2 right'>
                        <div className='action'>
                          <h3>Action:</h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: caseStudy.acf.bodyAction,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </InView>

              {/* SERVICES */}
              {/* <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block pad-sides pad-bottom ' + (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <div className='center-holder'>
                        <h4>Services Provided</h4>
                      </div>
                      <ServiceList limit={2} />
                      <div
                        className='center-holder'
                        style={{ marginTop: '30px' }}
                      >
                        <BgLink
                          to='/services'
                          text='All Services »'
                          isButton={true}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </InView> */}

              {/* CASE STUDIES */}
              {/* <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block pad-sides cases bg-light-grey ' +
                      (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <div className='text center-holder'>
                        <h4>Related Case Studies</h4>
                      </div>
                      <CaseList limit={3} />
                      <div
                        className='center-holder'
                        style={{ marginTop: '30px' }}
                      >
                        <BgLink
                          to='/case-studies'
                          text='All Case Studies »'
                          isButton={true}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </InView> */}
            </div>
          )}
        </InView>
        <Footer hasUpper={true} />
      </div>
    )
  }
}

export default CaseStudiesItemPage
