import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import ServiceItem from './ServiceItem'

// This is the query that Apollo Client will send to the WP site.
const GET_SERVICES = gql`
  {
    services {
      edges {
        node {
          id
          link
          title
          slug
          featuredImage {
            sourceUrl
          }
          acf {
            excerpt
          }
        }
      }
    }
  }
`

const ServiceList = (props) => {
  return (
    <div className='service-list'>
      <Query query={GET_SERVICES}>
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>
          if (error) return <p>Error :(</p>
          if (!data.services.edges.length)
            return <p>No matching posts found.</p>

          let services = data.services.edges

          let start = 0
          if (props.currentSlug) {
            // return the index of the first matching element
            let match = services.findIndex((element) => {
              return element.node.slug === props.currentSlug
            })
            start = match + 1
          }

          let limit = Number(services.length)
          if (props.limit) limit = props.limit

          // copy onto itself
          services = services.concat(services)

          // splice from starting services to limit
          services = services.slice(start, Number(start) + Number(limit))

          // if (props.limit) {
          //   services = services.slice(0, props.limit);
          // }
          return services.map((edge) => {
            return <ServiceItem key={edge.node.id} post={edge.node} />
          })
        }}
      </Query>
    </div>
  )
}

export default ServiceList
