import React from 'react'
// import styles from '../styles/components/Header.scss';
// import { useInView } from 'react-intersection-observer'
import { InView } from 'react-intersection-observer'
import { NavLink } from 'react-router-dom'

import BgLink from '../components/BgLink'
import LineLink from '../components/LineLink'

const Footer = (props) => {
  return (
    <div id='footer'>
      {props.hasUpper && (
        <InView>
          {({ inView, ref }) => (
            <div className={'upper ' + (inView ? 'in-view' : '')} ref={ref}>
              <div className='inner grid-10-2 fade-in'>
                <div className='left'>
                  <h1>
                    Get in touch to see how we can elevate <br />
                    your business in China.
                  </h1>
                  <BgLink
                    to='/contact'
                    text='Contact Us »'
                    isButton={true}
                    isWhite={true}
                  />
                </div>
                <div className='right'>
                  <div className='logo'>
                    <NavLink to='/'>
                      <img src='/images/vi/hgr_logo_white.png' alt='logo' />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          )}
        </InView>
      )}

      <InView>
        {({ inView, ref }) => (
          <div className={inView ? 'in-view' : ''} ref={ref}>
            {props.hasLower && (
              <div className='lower'>
                <div className='inner grid-10-2 fade-in'>
                  <div className='left'>
                    <div className='column'>
                      <h4>
                        <LineLink to='/services' text='Our Services' />
                      </h4>
                      <ul className='text-only'>
                        <li>
                          <LineLink
                            to='/services/corporate-formation'
                            text='Corporate Formation'
                          />
                        </li>
                        <li>
                          <LineLink
                            to='/services/business-financial-advisory'
                            text='Business & Financial Advisory'
                          />
                        </li>
                        <li>
                          <LineLink
                            to='/services/tax-advisory-compliance'
                            text='Tax Advisory & Compliance'
                          />
                        </li>
                        <li>
                          <LineLink
                            to='/services/hr-advisory-outsourcing-solutions'
                            text='HR Consultancy & Outsourcing Solutions'
                          />
                        </li>
                        {/* <li>
                          <LineLink
                            to='/services/hr-solutions'
                            text='HR Solutions'
                          />
                        </li> */}
                        {/* <li>
                          <LineLink
                            to='/services/mergers-acquisitions'
                            text='Mergers & Acquisitions'
                          />
                        </li> */}
                        {/* <li>
                          <LineLink
                            to='/services/accounting'
                            text='Accounting'
                          />
                        </li> */}
                      </ul>
                    </div>
                    <div className='column'>
                      <h4>
                        <LineLink
                          to='/case-studies'
                          text='Latest Case Studies'
                        />
                      </h4>
                      <ul className='text-only'>
                        <li>
                          <LineLink
                            to='/case-studies/pre-ipo-accounting-audit-for-listed-australian-energy-company'
                            text='Pre-IPO Accounting Audit for a Joint Venture Between an Energy Company and SOE'
                          />
                        </li>
                        <li>
                          <LineLink
                            to='/case-studies/tax-advisory-services-for-global-architectural-design-firm'
                            text='Tax Advisory Services for Global Architectural Design Firm'
                          />
                        </li>
                        <li>
                          <LineLink
                            to='/case-studies/ma-due-diligence-for-global-leader-in-crystals-and-jewelry'
                            text='M&A Diligence for a Global Leader in Crystals and Jewelry'
                          />
                        </li>
                        <li>
                          <LineLink
                            to='/case-studies/financial-reporting-system-enhancement-for-world-leading-parking-management-company'
                            text='Financial Reporting System Enhancement for a World Leading Parking Management Company'
                          />
                        </li>
                        <li>
                          <LineLink
                            to='/case-studies/regulatory-compliance-for-largest-supplier-of-automotive-engineering-consultants-in-china'
                            text='Regulatory Compliance for the Largest Supplier of Automotive Engineering Consultants in China'
                          />
                        </li>
                        <li>
                          <LineLink
                            to='/case-studies/human-resource-support-for-a-foreign-client-relocating-operations-to-a-new-province-in-china'
                            text='Human Resource Support for a Foreign Client Relocating Operations to a New Province in China'
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='right'>
                    <div className='column'>
                      <h4>
                        <LineLink to='/about' text='About&nbsp;Us' />
                      </h4>
                      <h4>
                        <LineLink to='/contact' text='Contact&nbsp;Us' />
                      </h4>
                      {/* <h4>
                        <LineLink to='' text='中文' />
                      </h4> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className='credit'>
              <div className='inner fade-in'>
                <p>
                  © <span className='hide'>Copyright </span>HGR Consulting. All
                  rights reserved. <span className='hide'>Credit:</span>{' '}
                  <a
                    href='https://lantern.digital'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    web design
                  </a>
                </p>
              </div>
            </div>
          </div>
        )}
      </InView>
    </div>
  )
}
export default Footer
