import React from 'react'
import { Helmet } from 'react-helmet'
import { pageLoad } from '../js/functions'
import { InView } from 'react-intersection-observer'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Breadcrumb from '../components/Breadcrumb'
import ScrollDown from '../components/ScrollDown'

class AboutPage extends React.Component {
  componentDidMount() {
    pageLoad()
  }

  render() {
    const header = {
      sub: 'About HGR Consulting',
      main: 'Global Experience · Personal Touch ',
      image: '/images/banner/main.jpg',
    }
    const breadcrumb = [
      {
        text: 'Home',
        link: '/',
      },
      {
        text: 'About Us',
      },
    ]

    const team = {
      pavo: {
        title: 'Founder',
        name: 'Pavo Tan',
        description:
          'Instead of asking why, he pushes to answer why not. His “ Master Plan” approach to working with clients delivers quality information while providing valuable insight to trends for the future.',
        image: '/images/team/Pavo-Tan.jpg',
        caption: 'Insight',
      },
      shiron: {
        title: 'Cofounder',
        name: 'Shiron Sun',
        description:
          'Putting her background in Law  and extensive network in Shanghai to use, Shiron navigates through the complexities of China’s Labor Policies making sure to bring the latest information and best practices to clients.',
        image: '/images/team/Shiron-Sun.jpg',
        caption: 'Connect',
      },
    }

    return (
      <div>
        <Helmet>
          <title>
            HGR Consulting | About Us: Global Experience, Personal Touch
          </title>
        </Helmet>

        <Header params={header} />
        <Breadcrumb items={breadcrumb} />

        <InView>
          {({ inView, ref }) => (
            <div
              id='about'
              className={'main fade-in ' + (inView ? 'in-view-wrap' : '')}
              ref={ref}
            >
              <ScrollDown />

              {/* TITLE BLOCK */}
              <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block pad-sides title ' + (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <h1>
                        HGR Consulting is a Shanghai-based, client-focused
                        boutique Business Consultancy, Financial Advisory &
                        Accounting firm, serving international clients doing or
                        planning a business in China.
                      </h1>
                    </div>
                  </div>
                )}
              </InView>

              {/* OUR BACKGROUND */}
              {/* <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block background pad-sides text-image image-right bg-light-grey ' +
                      (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <div className='image'>
                        <img src='/images/content/wall-dao-jun.jpg' alt='' />
                      </div>
                      <div className='text'>
                        <div className='inner'>
                          <h2>Our Background</h2>
                          <p>
                            Starting, developing and expanding a business in
                            China present unique challenges, both operational
                            and regulatory.
                          </p>
                          <p>
                            We know, because HGR Consulting supports clients,
                            typically privately owned as well as public-listed
                            SMEs, across various industry sectors to identify,
                            establish and realize successful opportunities in
                            the world’s second largest economy.
                          </p>
                          <p>
                            With our in-depth market oriented experiences,
                            extensive resources and close government
                            relationships, we help achieve each client’s unique
                            short-, medium- and long-term China goals by
                            providing a complete suite of Business Consultancy
                            and Financial Advisory services which are
                            complemented and supported by our Market
                            Entry/Corporate Formation, Accounting, Tax and
                            HR/Payroll services, carefully tailored to the needs
                            of our clients.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </InView> */}

              {/* OUR ADVANTAGE */}
              {/* <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block advantage pad-sides text-image ' +
                      (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <div className='image'>
                        <img src='/images/content/painting-he.jpg' alt='' />
                      </div>
                      <div className='text'>
                        <div className='inner'>
                          <h2>Our Advantage</h2>
                          <p>
                            Our clients appreciate that we take time to
                            understand their objectives. We then guide and
                            support them through China’s complex financial and
                            regulatory systems so they can focus on their core
                            business strengths. Accurate, up-to-date financial
                            management, compliance information and peerless
                            advice enable senior managers to make informed,
                            timely decisions that optimize their China strategy.
                          </p>
                          <p>
                            As a boutique, client-focused firm, we compete on
                            service, quality work and exemplary client servicing
                            rather than price. Our work is precise and
                            professionally delivered, yet we employ out of the
                            box thinking that results in attainable and
                            forward-thinking solutions. We are always looking
                            for better ways of doing things and have worked hard
                            to develop streamlined processes that are efficient
                            and non-intrusive. We will not compromise the client
                            experience for any reason. Most of our clients have
                            come to us through a personal recommendation,
                            evidence of our knowledge, experience and commitment
                            to service in this field.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </InView> */}

              {/* TEAM */}
              <InView>
                {({ inView, ref }) => (
                  <div className='block team pad-sides team bg-light-grey'>
                    <div
                      className={
                        'inner fade-in ' + (inView ? 'in-view-wrap' : '')
                      }
                      ref={ref}
                    >
                      <h1>Key Personnel</h1>
                      <div className='team-list'>
                        <TeamItem data={team.pavo} />
                        <TeamItem data={team.shiron} />
                      </div>
                    </div>
                  </div>
                )}
              </InView>
            </div>
          )}
        </InView>

        <Footer hasUpper={true} hasLower={true} />
      </div>
    )
  }
}

const TeamItem = ({ data }) => {
  const { title, name, description, image, caption } = data
  return (
    <InView>
      {({ inView, ref }) => (
        <div
          key={name}
          className={'team-item fade-in ' + (inView ? 'in-view' : '')}
          ref={ref}
        >
          <div className='image'>
            <img src={image} alt='' />
          </div>
          <div className='text'>
            <h4 className='caption'>{caption}</h4>
            <h4>{title}</h4>
            <h2>{name}</h2>
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
      )}
    </InView>
  )
}

export default AboutPage
