import React from 'react'
import './styles/styles.scss'
import AppRouter from './routers/AppRouter'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'

const client = new ApolloClient({
  // Change this to the URL of your WordPress site.
  // uri: "http://localhost:8888/hgr/wp/graphql",
  // uri: 'https://hgr.lanternbox.com/wp/graphql',
  uri: 'https://www.hgr-consulting.com/wp/graphql',
})

function App() {
  return (
    <ApolloProvider client={client}>
      <AppRouter></AppRouter>
    </ApolloProvider>
  )
}

export default App
