import React from 'react'
import { Link } from 'react-router-dom'
import BgLink from '../components/BgLink'
import { useInView } from 'react-intersection-observer'

const CaseItem = (props) => {
  const { id, title, slug, featuredImage } = props.post
  const { category, excerpt } = props.post.acf
  const [ref, inView] = useInView()

  return (
    <div
      key={id}
      className={`item fade-in ${inView ? 'in-view' : ''}`}
      ref={ref}
    >
      {featuredImage && featuredImage.sourceUrl && (
        <Link to={'/case-studies/' + slug}>
          <img
            src={
              featuredImage.sourceUrl // If a featured image exists, display it.
            }
            alt=''
          />
        </Link>
      )}
      <h4 className='tag'>{category}</h4>
      <h3 dangerouslySetInnerHTML={{ __html: title }} />
      {props.hasExcerpt && (
        <>
          <p>{excerpt}</p>
          <p className='more'>
            <BgLink to={'/case-studies/' + slug} text='Read More »' />
          </p>
        </>
      )}
    </div>
  )
}

export default CaseItem
