import React from 'react'
import { Helmet } from 'react-helmet'
import { pageLoad } from '../js/functions'
import { InView } from 'react-intersection-observer'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Breadcrumb from '../components/Breadcrumb'
import ServiceList from '../components/ServiceList'
import ScrollDown from '../components/ScrollDown'

class ServicesPage extends React.Component {
  componentDidMount() {
    pageLoad()
  }

  render() {
    const header = {
      sub: 'Our Services',
      main:
        'Tailor Made Support Platforms that Keep You Focused on the Big Picture',
      image: '/images/banner/main.jpg',
    }
    const breadcrumb = [
      {
        text: 'Home',
        link: '/',
      },
      {
        text: 'Our Services',
      },
    ]

    return (
      <div>
        <Helmet>
          <title>HGR Consulting | Services: for Growth in China</title>
        </Helmet>

        <Header params={header} />
        <Breadcrumb items={breadcrumb} />

        <InView>
          {({ inView, ref }) => (
            <div
              id='service-list-page'
              className={'main fade-in ' + (inView ? 'in-view-wrap' : '')}
              ref={ref}
            >
              <ScrollDown />

              <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block pad-sides title ' + (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <h1>
                        The HGR Suite of Services provides advisory you can
                        trust and administrative support you count on.
                      </h1>
                    </div>
                  </div>
                )}
              </InView>

              {/* ABOUT - INTRO */}
              <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block pad-sides text-image image-bigger ' +
                      (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <div className='text'>
                        <p>
                          Trust is key to any business operating in a foreign
                          country. Language and cultural barriers complicate
                          daily operations even without the worries of ensuring
                          you are compliant with local regulations. Whether your
                          business begins in China or is an expansion from your
                          home base, knowing that the advice you receive is
                          relevant and timely allows you to focus on the big
                          picture.
                        </p>
                        <p>
                          The services provided by HGR Consulting are not
                          unique. Our approach to working with our clients is.
                          We ensure we understand where you are now and where
                          you want to go. Then we provide you with a suite of
                          services that support your growth. Our success depends
                          on our clients, so we offer advisory that is clear and
                          concise in supporting your next big move while
                          providing support services that deliver quality
                          information to your leaders on the ground and at
                          headquarters abroad.
                        </p>
                      </div>
                      <div className='image'>
                        <img
                          src='/images/content/painting-4-parts.jpg'
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                )}
              </InView>

              <InView>
                {({ inView, ref }) => (
                  <div
                    className={
                      'block pad-sides divider center ' +
                      (inView ? 'in-view' : '')
                    }
                    ref={ref}
                  >
                    <div className='inner fade-in'>
                      <div className='line'></div>
                    </div>
                  </div>
                )}
              </InView>

              <div className='block pad-sides pad-bottom'>
                <div className='inner'>
                  <InView>
                    {({ inView, ref }) => (
                      <div
                        className={
                          'center-holder fade-in ' + (inView ? 'in-view' : '')
                        }
                        ref={ref}
                      >
                        <h4>Service List</h4>
                      </div>
                    )}
                  </InView>
                  <ServiceList />
                </div>
              </div>
            </div>
          )}
        </InView>

        <Footer hasUpper={true} />
      </div>
    )
  }
}

export default ServicesPage
