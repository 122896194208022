import React from 'react'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import CaseItem from './CaseItem'

// This is the query that Apollo Client will send to the WP site.
const GET_CASE_STUDIES = gql`
  {
    case_studies {
      edges {
        node {
          id
          link
          title
          slug
          featuredImage {
            sourceUrl
          }
          acf {
            category
            excerpt
          }
        }
      }
    }
  }
`

const CaseList = (props) => {
  return (
    <div className='case-list'>
      <Query query={GET_CASE_STUDIES}>
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>
          if (error) return <p>Error :(</p>
          if (!data.case_studies.edges.length)
            return <p>No matching posts found.</p>

          let cases = data.case_studies.edges

          if (props.limit) {
            cases = cases.slice(0, props.limit)
          }
          return cases.map((edge) => {
            return (
              <CaseItem
                key={edge.node.id}
                post={edge.node}
                hasExcerpt={props.hasExcerpt}
              />
            )
          })
        }}
      </Query>
    </div>
  )
}

export default CaseList
